let t;
const setIntl = intl => {
  t = intl;
};

function GT(textKey) {
  return t(textKey);
}

// 客户类别编码
class CustomerTypeCode {
  static map = {
    SOLDTO: 'SOLDTO',
    SHIPTO: 'SHIPTO',
    PAYER: 'PAYER',
    INDRIE: 'INDRIE',
  };
  static list = Object.keys(CustomerTypeCode.map).map(v => ({ label: CustomerTypeCode.map[v], value: v }));
}

class CustomerType {
  static map = {
    1: '售达方',
    2: '送达方',
    3: '付款方',
    4: '非直供',
    5: '经销商',
    6: '仓库',
    7: '售点',
    8: '批发',
  };
  static list = Object.keys(CustomerType.map).map(v => ({ label: CustomerType.map[v], value: parseInt(v) }));
}

// 字段类型
class FieldType {
  static map = {
    1: '映射字段',
    0: '自有字段',
  };
  static list = Object.keys(FieldType.map).map(v => ({ label: FieldType.map[v], value: parseInt(v) }));
}

// 字段值类型
class FieldValueType {
  static map = {
    string: 'string',
    int: 'int',
    double: 'double',
    object: 'object',
  };
  static list = Object.keys(FieldValueType.map).map(v => ({ label: FieldValueType.map[v], value: v }));
}

// 上游系统
class UpstreamSystem {
  static map = {
    mdm: 'MDM',
    ops: 'Pass+',
  };
  static list = Object.keys(UpstreamSystem.map).map(v => ({ label: UpstreamSystem.map[v], value: v }));
}

// 组织类型
class OrgType {
  static map = {
    1: 'DBC',
    2: 'HR',
    3: '中台',
  };
  static list = Object.keys(OrgType.map).map(v => ({ label: OrgType.map[v], value: parseInt(v) }));
}

// 组织状态
class OrgStatus {
  static map = {
    0: '禁用',
    1: '启用',
  };
  static list = Object.keys(OrgStatus.map).map(v => ({ label: OrgStatus.map[v], value: parseInt(v) }));
}

class TableSource {
  static map = {
    customer: '客户',
    organization: '组织架构',
    employee: '员工',
    dictionary: '字典',
    region: '行政区划',
  };
  static list = Object.keys(TableSource.map).map(v => ({ label: TableSource.map[v], value: v }));
}

class DataType {
  static map = {
    1: '客户',
    2: '员工',
    3: '组织架构',
    4: '行政区划',
    5: '字典',
  };
  static list = Object.keys(DataType.map).map(v => ({ label: DataType.map[v], value: parseInt(v) }));
}

class GatewayStatus {
  static map = {
    0: { label: '停用', color: 'red' },
    1: { label: '启用', color: 'green' },
  };
  static list = Object.keys(GatewayStatus.map).map(v => ({ label: GatewayStatus.map[v].label, value: parseInt(v) }));
}

class TaskStatus {
  static map = {
    1: { label: '待分发', color: 'default' },
    2: { label: '分发中', color: 'orange' },
    3: { label: '分发失败', color: 'red' },
    4: { label: '分发成功', color: 'green' },
    5: { label: '分发终止', color: 'default' },
  };
  static list = Object.keys(TaskStatus.map).map(v => ({ label: TaskStatus.map[v].label, value: parseInt(v) }));
}

class LogType {
  static map = {
    1: '数据变更日志',
    2: '分发任务日志',
    3: '上游推送日志',
  };
  static list = Object.keys(LogType.map).map(v => ({ label: LogType.map[v], value: parseInt(v) }));
}

class LogStatus {
  static map = {
    1: { label: '成功', color: 'green' },
    2: { label: '失败', color: 'red' },
    3: { label: '待自动重试', color: 'orange' },
    4: { label: '数据处理失败', color: 'red' },
    5: { label: '处理中', color: 'orange' },
  };
  static list = Object.keys(LogStatus.map).map(v => ({ label: LogStatus.map[v].label, value: parseInt(v) }));
}

class TaskCenterStatus {
  static map = {
    0: { label: '待处理', color: 'orange' },
    1: { label: '处理中', color: 'purple' },
    2: { label: '已完成', color: 'green' },
    3: { label: '处理失败', color: 'red' },
    4: { label: '已取消', color: 'default' },
    6: { label: '部分成功', color: 'orange' },
  };
  static list = Object.keys(TaskCenterStatus.map).map(v => ({
    label: TaskCenterStatus.map[v].label,
    value: parseInt(v),
  }));
}

// 任务中心枚举配置，请注意：新增导入导出code必须配置TaskCenterTypes和ExportTypes
class TaskCenterTypes {
  static map = {
    36: { label: '导出客户' },
    37: { label: '导出组织' },
  };
  static list = Object.keys(TaskCenterTypes.map).map(v => ({
    label: TaskCenterTypes.map[v].label,
    value: parseInt(v),
  }));
}

class ExportTypes {
  static map = {
    PT_EXPORT_CUSTOMER: 36, // 导出客户
    PT_EXPORT_ORGANIZATION: 37, // 导出组织架构
  };
}

export {
  CustomerType,
  CustomerTypeCode,
  DataType,
  ExportTypes,
  FieldType,
  FieldValueType,
  GT,
  GatewayStatus,
  LogStatus,
  LogType,
  OrgStatus,
  OrgType,
  TableSource,
  TaskCenterStatus,
  TaskCenterTypes,
  TaskStatus,
  UpstreamSystem,
  setIntl,
};
